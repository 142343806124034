import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

function Heading() {
  const intl = useIntl();
  return (
    <>
      <div
        className="masthead masthead-blog"
        style={{ position: 'relative', height: '40vh' }}
      >
        <div className="masthead-contents">
          <div className="masthead-text">
            <h1 className="small">
              {intl.formatMessage({ id: 'emi.emiTitle' })}
              {` `}
              <strong>{intl.formatMessage({ id: 'emi.calculator' })}</strong>
            </h1>
            <p className="sub-lead">
              {intl.formatMessage({ id: 'emi.descNormal' })}
              <strong>{intl.formatMessage({ id: 'emi.descStrong' })}</strong>
            </p>
          </div>
        </div>
      </div>
      <div className="masthead-placeholder" style={{ height: '4vh' }} />
    </>
  );
}

export default Heading;
