import React from 'react';
import './layout.scss';

const Row = ({ children }) => <div className="row">{children}</div>;
const Col = ({ children, className }) => (
  <div className={`col ${className || ''}`}>{children}</div>
);
const Table = ({ children }) => (
  <div className="table-responsive">
    <table className="table">{children}</table>
  </div>
);
const Form = ({ children }) => <form className="form">{children}</form>;

export { Row, Col, Table, Form };
