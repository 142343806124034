/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState, useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { v4 as uuidv4 } from 'uuid';
import { numbers as convertToNepali } from '@danfebooks/nepalinumbers';
import { window } from 'browser-monads';

import { Row, Col, Table, Form } from '../Layout';
import './calculator.scss';

export default function form() {
  const intl = useIntl();
  const isNe = intl.locale === 'ne';

  const sessionformInputs = window.JSON.parse(
    window.sessionStorage?.getItem('formInputs') || '{}',
  );
  const sessionTable = window.JSON.parse(
    window.sessionStorage?.getItem('emiTable') || '[]',
  );

  const [formInputs, setformInputs] = useState(
    sessionformInputs || {
      isMonthly: false,
    },
  );

  const [emiTable, setEmiTable] = useState(sessionTable || []);
  const [error, setError] = useState({ err: false });

  const emiCalculator = () => {
    let { iRate, lTerm } = formInputs;
    const { lAmount } = formInputs;

    if (!formInputs.isMonthly) {
      lTerm *= 12;
    }

    // check inputs value shuld not be a number or less than 0
    if (
      isNaN(lAmount) ||
      isNaN(lTerm) ||
      isNaN(iRate) ||
      iRate <= 0 ||
      lTerm <= 0 ||
      lAmount <= 0
    ) {
      setError((prev) => ({ ...prev, err: true }));
    } else {
      setError((prev) => ({ ...prev, err: false }));
    }

    // one month intrest
    iRate /= 12 * 100;
    let lTermInMonth = Math.round(lTerm);
    const emiTableArray = [];

    while (lTermInMonth > 0) {
      // calculated emi permont

      const calculatedEmi =
        (lAmount * iRate * (1 + iRate) ** lTerm) / ((1 + iRate) ** lTerm - 1);

      // calculated total intrest
      const totalIntrest =
        ((calculatedEmi * lTerm - lAmount) / lTerm) * lTermInMonth;

      // calculated balance
      const balance = lAmount - (calculatedEmi * lTermInMonth - totalIntrest);

      // emi obj
      const emiTableObj = {
        period: lTermInMonth,
        paymentAmount: calculatedEmi.toFixed(2),
        totalIntrest: totalIntrest.toFixed(2),
        totalPayments: (calculatedEmi * lTermInMonth).toFixed(2),
        balance: balance.toFixed(2),
      };

      emiTableArray.unshift(emiTableObj);
      lTermInMonth -= 1;
    }

    setEmiTable(emiTableArray);
  };

  useEffect(() => {
    window.sessionStorage.setItem(
      'formInputs',
      window.JSON.stringify(formInputs),
    );
  }, [formInputs]);
  useEffect(() => {
    window.sessionStorage.setItem('emiTable', window.JSON.stringify(emiTable));
  }, [emiTable]);

  const handleInputChange = (e) => {
    e.persist();
    setformInputs((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="site-wrap">
      <div className="site-container">
        <div className="site-content site-content-posts site-content-posts-large">
          <Form>
            <Row>
              <Col className="lg">
                <label htmlFor="txtlAmount" className="calc-label">
                  {intl.formatMessage({ id: 'emi.loanAmount' })}:
                </label>
                <input
                  type="number"
                  inputMode="decimal"
                  id="txtlAmount"
                  name="lAmount"
                  value={formInputs.lAmount}
                  placeholder="15,00,000"
                  required
                  onChange={handleInputChange}
                />
              </Col>
              <Col className="sm">
                <label htmlFor="txtiRate" className="calc-label">
                  {intl.formatMessage({ id: 'emi.intRate' })}(%):
                </label>
                <input
                  type="number"
                  inputMode="decimal"
                  id="txtiRate"
                  name="iRate"
                  value={formInputs.iRate}
                  placeholder="10.5"
                  className="sm"
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col className="xs">
                <label htmlFor="txtlTerm" className="calc-label">
                  {intl.formatMessage({ id: 'emi.loanTerm' })}
                </label>
                <input
                  type="number"
                  inputMode="decimal"
                  id="txtlTerm"
                  name="lTerm"
                  value={formInputs.lTerm}
                  placeholder="7"
                  className="sm"
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col className="xs">
                <ul className="tabs">
                  <li>
                    <button
                      type="button"
                      name="monthly"
                      className={formInputs.isMonthly ? 'active' : ''}
                      onClick={() => {
                        setformInputs((prev) => ({
                          ...prev,
                          isMonthly: true,
                        }));
                      }}
                    >
                      {intl.formatMessage({ id: 'emi.month' })}
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      name="annul"
                      className={!formInputs.isMonthly ? 'active' : ''}
                      onClick={() => {
                        setformInputs((prev) => ({
                          ...prev,
                          isMonthly: false,
                        }));
                      }}
                    >
                      {intl.formatMessage({ id: 'emi.Annual' })}
                    </button>
                  </li>
                </ul>
              </Col>
              <Col>
                <button
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    emiCalculator();
                  }}
                  className="masthead-buttons masthead-button-appreciate sm"
                >
                  {intl.formatMessage({ id: 'emi.calcBtn' })}
                </button>
              </Col>
            </Row>
          </Form>
          {!error.err && emiTable.length > 0 ? (
            <>
              <h2 className="calc-result-title">
                {intl.formatMessage({ id: 'emi.emi' })}
              </h2>
              <Table>
                <thead className="thead-dark">
                  <tr>
                    <th>#</th>
                    <th>{intl.formatMessage({ id: 'emi.paymentAmount' })}</th>
                    <th>{intl.formatMessage({ id: 'emi.totalIntrest' })}</th>
                    <th>{intl.formatMessage({ id: 'emi.totalPayments' })}</th>
                    <th>{intl.formatMessage({ id: 'emi.balance' })}</th>
                  </tr>
                </thead>
                <tbody>
                  {emiTable.map((item) => (
                    <tr key={uuidv4()}>
                      <td>
                        {isNe ? convertToNepali(item.period) : item.period}
                      </td>
                      <td>
                        {isNe
                          ? convertToNepali(item.paymentAmount)
                          : item.paymentAmount}
                      </td>
                      <td>
                        {isNe
                          ? convertToNepali(item.totalIntrest)
                          : item.totalIntrest}
                      </td>
                      <td>
                        {isNe
                          ? convertToNepali(item.totalPayments)
                          : item.totalPayments}
                      </td>
                      <td>
                        {isNe ? convertToNepali(item.balance) : item.balance}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          ) : (
            <div className="alert">
              {intl.formatMessage({ id: 'emi.alertMessage' })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
