import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import SEO from '../../components/SEO';
import Layout from '../../layout';
import Footer from '../../components/Footer/index';
import PageHeading from '../../components/EMI/pageHeading';
import Calculator from '../../components/EMI/calculator';

const emi = () => {
  const intl = useIntl();
  return (
    <Layout>
      <SEO
        meta={{
          title: `${intl.formatMessage({
            id: 'emi.emiTitle',
          })} ${intl.formatMessage({
            id: 'emi.calculator',
          })} | ${intl.formatMessage({ id: 'title' })}`,
          desc: `${intl.formatMessage({
            id: 'emi.descNormal',
          })} ${intl.formatMessage({ id: 'emi.descStrong' })}`,
          slug: `/calculators/emi`,
        }}
        blog
      />
      <PageHeading />
      <Calculator />
      <Footer />
    </Layout>
  );
};

export default emi;
